import { getConfig } from '@/lib/get-config';
import type {
  BaseInstance,
  IBaseInstanceConfig,
  InferMonitoring,
  ModelName,
} from '@pigello/pigello-matrix';
import { patch } from './api/patch';
import { post } from './api/post';
import {
  convertResponse,
  toExternalFieldNames,
  toInternalFieldNames,
} from './instanceMapper';
import type { GenericMutateOptions, Meta, QueryParams } from './types';
export const mutateGenericInstance = async <Instance extends BaseInstance>({
  id,
  body,
  options,
  modelName,
}: {
  id: string;
  body: Partial<Instance>;
  modelName: ModelName;
  options?: GenericMutateOptions;
}) => {
  const config = getConfig<ModelName, Instance>(modelName, true);
  if (!config) {
    throw new Error('No config found');
  }
  const url =
    id === '' ? `${config.instanceUrl}/` : `${config.instanceUrl}/${id}/`;
  const bodyToSend = toExternalFieldNames(
    config,
    body,
    options?.convertRelations
  );

  const res = await patch<Instance>({
    url: url,
    body: bodyToSend,
  });

  return toInternalFieldNames<Instance>(config, res.data);
};

export const postInstancesData = async <Instance extends BaseInstance>({
  config,
  data,
  overrideUrl,
  queryParams,
  skipOrganizationOnExternalFieldNames,
}: {
  config: IBaseInstanceConfig<Instance>;
  data: Instance[];
  overrideUrl?: string;
  queryParams?: QueryParams;
  skipOrganizationOnExternalFieldNames?: string[];
}) => {
  const url = overrideUrl ?? config.listUrl;

  const res = await post<Instance>({
    url: url,
    body: data,
    queryParams,
    skipOrganizationOnExternalFieldNames,
  });

  window.queryClient?.invalidateQueries({
    queryKey: [config.modelName, { modelName: config.modelName }],
  });
  window.queryClient?.invalidateQueries({
    queryKey: [
      config.modelName,
      'list',
      {
        modelName: config.modelName,
      },
    ],
  });

  if (Array.isArray(res.data)) {
    return res.data;
  }

  const convertedData = convertResponse<Instance>({
    config,
    response: res.data as unknown as { data: Instance[]; meta: Meta },
  });

  return convertedData as unknown as Instance[] | InferMonitoring<Instance>[];
};
